import React, { useEffect, useRef, useState } from 'react'
import Layout from '../components/layout/Layout'
import BankTransfer from '../components/bankTransfer/BankTransfer';
import Bank from '../components/bank/Bank';
import '../components/card/card.scss'
import { useNavigate,useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { BeatLoader } from 'react-spinners';

function CardDetails() {


  useEffect(()=>{
    if(!localStorage.getItem('auth')) return navigate('/')
  // eslint-disable-next-line
  },[])
  const auth = JSON.parse(localStorage.getItem('auth'))
  const dashData = JSON.parse(localStorage.getItem('dashResult'))

  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBank, setSelectedBank] = useState(""); // State to hold the selected bank
  const [otp, setOtp] = useState(['', '', '', '']); // Initialize an array with empty strings for each digit
  const otpInputs = [useRef(null), useRef(null), useRef(null), useRef(null)]; // Create refs for each input field
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line
  const [isNubanActive,setNubanActive] = useState(true)

  
  const  {cardValues} =  location?.state || {}
  const accessCode = cardValues?.accessCode || dashData?.accessCode
  
  const openModal = () => {
      setIsModalOpen(!isModalOpen);
  };

  
  const closeModal = () => {
    setIsModalOpen(false);
  };
  // eslint-disable-next-line
  const handleBankSelection = (bankName) => {
    setSelectedBank(bankName);
    closeModal();
  };
  const renderContent = ()=>{
    switch(selectedBank.name){
        case "Card":
            return "Card";
        
        case "Transfer":
            return <BankTransfer />;
       
        case "Pay by Bank":
            return <Bank />;
       
        default:
            return "Card"
    }
   
  }

  const handleInputChange = (e, index) => {
    let value = e.target.value;

    if (value.length > 1) {
        value = value.charAt(0);
    }

    if (/^[0-9]*$/.test(value) && index >= 0 && index < otpInputs.length) {
      otp[index] = value;
      setOtp([...otp]);

      // Auto focus on the next input field if available
      if (index < otpInputs.length - 1 && value !== '') {
        otpInputs[index + 1].current.focus();
      }
    }
  };
  const handleSubmite = async (e)=>{
    e.preventDefault();
    setIsLoading(true);
    const enteredOTP = otp.join('');
    
    const pinData ={
      transactionId: cardValues?.transactionId || cardValues?.TransactionId ,
      pin: enteredOTP
    }
   
    try{
      const headers = {  
        Authorization:`Bearer ${auth?.livePublicKey}`
      }
      const url ="https://e59kqicjn1.execute-api.us-east-2.amazonaws.com/production/verify-pin"
        
      let res = await axios.post(url,{...pinData},{
        headers:headers
      }) 
      

      if(res?.data?.status==="success" && res.data.authorizationMode ==="otp"){
        setIsLoading(false);
        return navigate('/pages/card-otp',{ state: { cardValues } })
      }else if(res.data.status==="success" && (res?.data?.authorizationMode !=="otp" || res.data.authorizationMode !=="pin")){
        setIsLoading(false);
        
        let cardResponse = res.data
        return navigate('/pages/success',{ state: {cardResponse} })

      }if(res?.data?.status ==="success" && res?.data?.authorizationMode === "3ds"){
         if((res?.data?.authMessage).toLowerCase()==="3ds url" && res.data.responseUr){
          setIsLoading(false);
          window.location.href = res?.data?.responseUr;
          // navigate('/success',{ state: {status:"success"} })
        }else if((res.data.authMessage).toLowerCase()==="3ds" && !res.data.responseUr){
          setIsLoading(false);
          toast.error("unable to complete payment");
        }else{
          setIsLoading(false);
          toast.error("unable to complete payement");
        }
      }
      else{
        setIsLoading(false);
        toast.error('unable to complete transaction')
        navigate(`/pages/${accessCode}`)
      }
      
      setIsLoading(false);
      // navigate('/success')
    }catch(err){
      setIsLoading(false);
      console.log(err)
      const errorMessage = err?.response?.data?.message || err.message
      toast.error(errorMessage);
    }
    
  }
  return (
    <Layout pathback={`/pages/${accessCode}`}>
        <div className='input_wrapper'>
            
            <h1 className='option_title'>Payment option</h1>
            <p className='option_desc'>Please select a preferred payment option</p>
                <div className="input-container" onClick={openModal}>
                    {selectedBank ? (
                        <>
                        <h3 className="bank-name">{selectedBank.name}</h3>
                        </>
                    ) : (
                        <span >Card</span>
                    )}
                    <img
                        src={process.env.PUBLIC_URL + "/images/arrow-down.svg"}
                        alt="arrow"
                        style={{marginLeft:"auto"}}
                    />
                </div>

                {/* {isModalOpen && (
                <SelectDropDown
                    isNubanActive ={isNubanActive}
                    handleBankSelection={handleBankSelection}
                    closeModal={closeModal}
                    selectedBank={selectedBank}
                />
                )} */}

                <div className='rule'></div>
                 {
                    selectedBank.name ==="Card" || !selectedBank.name   ?
                    <div className='card-details'>
                        <h1 className='card_h'>Enter Card PIN</h1>
                        <p className='card_p'>Please enter your card 4-digit pin to authorise this payment</p>
                        <form className='card_pin_form' onSubmit={handleSubmite}>
                            <div className='card_pin_container'>
                            {otp.map((digit, index) => (
                                <input
                                key={index}
                                type='number'
                                maxLength="1"
                                className='pin'
                                value={digit}
                                onChange={(e) => handleInputChange(e, index)}
                                ref={otpInputs[index]}
                                autoFocus={index === 0}
                                />
                            ))}
                            </div>
                            <button  className='btn'>
                              {
                                isLoading ? (
                                  <BeatLoader
                                  color="#FFF"
                                  cssOverride={{}}
                                />
                                  
                                ) : (
                                  <>
                                    Confirming Details
                                  </>
                                )
                              }
                              </button>
                        </form>
                    </div>:
                    renderContent()
                 }
        </div>
    
    </Layout>
  )
}

export default CardDetails
